<template>
  <div
    class="influencer-testimonials"
    :class="{
      [`dp--${desktopPadding}`]: mobilePadding,
      [`mp--${mobilePadding}`]: desktopPadding,
      [`mm--${mobileMargin}`]: mobileMargin,
      [`dm--${desktopMargin}`]: desktopMargin,
    }"
    :style="[
      backgroundColorCss,
      ringColorCss,
      titleColorCss,
      mobileSliderPaddingCss,
      desktopSliderPaddingCss,
    ]"
  >
    <div
      class="influencer-testimonials__bg-wrapper"
      :class="[`dp--${desktopPadding}`, `mp--${mobilePadding}`]"
    >
      <div class="influencer-testimonials__bg"></div>
    </div>
    <div
      ref="influncerTestimonialsSlider"
      class="influencer-testimonials__slider"
    >
      <component
        :is="testimonial.component"
        v-for="(testimonial, i) in testimonials"
        :key="'testimonial' + i"
        v-bind="testimonial"
        class="influencer-testimonials__slider__testimonial"
        :class="activeClass(i)"
        :active="active(i)"
        :scroll-direction="scrollDirection"
        :desktop-padding="desktopPadding"
        :index="i"
        :intersection-observer="intersectionObserver"
        @scrollData="scrollTo"
      />
      <div class="final-margin"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'InfluencerTestimonials',
  props: {
    desktopPadding: {
      type: String,
      default: '',
    },
    mobilePadding: {
      type: String,
      default: '',
    },
    desktopMargin: {
      type: String,
      default: '',
    },
    mobileMargin: {
      type: String,
      default: '',
    },
    testimonials: {
      type: Array,
      default: () => [],
    },
    backgroundColor: {
      type: String,
      default: 'grey',
    },
    ringColor: {
      type: String,
      default: null,
    },
    titleColor: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      intersectionObserver: undefined,
      activeIndex: 0,
      scrollDirection: 'right',
      previousX: 0,
      desktop: false,
    }
  },
  computed: {
    backgroundColorCss() {
      return { '--background-color': `var(--${this.backgroundColor})` }
    },
    ringColorCss() {
      return { '--parent-ring-color': `var(--${this.ringColor})` }
    },
    titleColorCss() {
      return { '--parent-title-color': `var(--${this.titleColor})` }
    },
    mobileSliderPaddingCss() {
      return {
        '--mobile-slider-padding': `var(--sp-${this.getSliderPadding()})`,
      }
    },
    desktopSliderPaddingCss() {
      return {
        '--desktop-slider-padding': `var(--sp-${this.desktopPadding})`,
      }
    },
  },
  mounted() {
    this.initIntersectionObserver()
    this.initDesktopQuery()
  },
  methods: {
    initIntersectionObserver() {
      this.intersectionObserver = new IntersectionObserver(
        this.intersectionHandler,
        { root: this.$refs.influncerTestimonialsSlider, threshold: 0.97 }
      )
    },
    intersectionHandler(entries) {
      entries.forEach((entry) => {
        // Scrolling left/right
        const currentX = entry.boundingClientRect.x

        currentX < this.previousX
          ? (this.scrollDirection = 'left')
          : (this.scrollDirection = 'right')

        // Intersecting
        if (entry.isIntersecting) {
          this.activeIndex = entry.target.__vue__.index
        }

        this.previousX = currentX
      })
    },
    scrollTo(scrollPosition, index = undefined) {
      if (index !== undefined) {
        index < this.activeIndex
          ? (this.scrollDirection = 'left')
          : (this.scrollDirection = 'right')
        this.activeIndex = index
      }

      this.$refs.influncerTestimonialsSlider.scrollTo({
        left: scrollPosition,
        behavior: 'smooth',
      })
    },
    initDesktopQuery() {
      const desktopQuery = window.matchMedia('(min-width: 1024px)')
      this.desktop = desktopQuery.matches

      desktopQuery.addEventListener('change', (e) => {
        this.desktop = e.matches
      })
    },
    activeClass(i) {
      if (i === this.activeIndex) return 'active'
      return ''
    },
    active(i) {
      return i === this.activeIndex
    },
    getSliderPadding() {
      switch (this.mobilePadding) {
        case 'xxxsmall':
          return 'xsmall'
        case 'xxsmall':
          return 'small'
        case 'xsmall':
          return 'medium'
        case 'small':
          return 'large'
        case 'medium':
          return 'xlarge'
        case 'large':
          return 'xxlarge'
        case 'xlarge':
          return 'xxxlarge'
        default:
          return this.mobilePadding
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.influencer-testimonials {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  padding-left: 0;
  padding-right: 0;

  &.margin-top-bottom {
    margin-top: spacing('large');
    margin-bottom: spacing('large');
  }

  &.margin-top {
    margin-top: spacing('large');
  }

  &.margin-bottom {
    margin-bottom: spacing('large');
  }

  .influencer-testimonials__slider {
    @include hide-scrollbars;

    display: flex;
    flex-flow: row nowrap;
    gap: spacing('small');
    padding-left: var(--mobile-slider-padding);
    z-index: 1;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    scroll-padding-left: var(--mobile-slider-padding);

    .final-margin {
      display: flex;
      width: 40%;
      height: 1px;
      flex-shrink: 0;
      z-index: 1;
    }
  }

  .influencer-testimonials__bg-wrapper {
    position: absolute;
    top: 18rem;
    width: 100%;
    display: flex;
    padding-top: 0;
    padding-bottom: 0;
  }

  .influencer-testimonials__bg {
    flex-grow: 1;
    height: 25rem;
    background-color: var(--background-color);
  }
}

// Desktop
@media screen and (min-width: $tablet-landscape) {
  .influencer-testimonials {
    .influencer-testimonials__slider {
      padding-left: var(--desktop-slider-padding);
      padding-right: var(--desktop-slider-padding);
      scroll-padding-left: var(--desktop-slider-padding);
      scroll-padding-right: var(--desktop-slider-padding);
    }

    .influencer-testimonials__slider__testimonial {
      &:hover {
        cursor: pointer;
      }
    }

    .influencer-testimonials__bg {
      height: 0rem;
    }
  }
}
</style>
