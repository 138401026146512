var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"influencer-testimonials",class:{
    [`dp--${_vm.desktopPadding}`]: _vm.mobilePadding,
    [`mp--${_vm.mobilePadding}`]: _vm.desktopPadding,
    [`mm--${_vm.mobileMargin}`]: _vm.mobileMargin,
    [`dm--${_vm.desktopMargin}`]: _vm.desktopMargin,
  },style:([
    _vm.backgroundColorCss,
    _vm.ringColorCss,
    _vm.titleColorCss,
    _vm.mobileSliderPaddingCss,
    _vm.desktopSliderPaddingCss,
  ])},[_c('div',{staticClass:"influencer-testimonials__bg-wrapper",class:[`dp--${_vm.desktopPadding}`, `mp--${_vm.mobilePadding}`]},[_c('div',{staticClass:"influencer-testimonials__bg"})]),_vm._v(" "),_c('div',{ref:"influncerTestimonialsSlider",staticClass:"influencer-testimonials__slider"},[_vm._l((_vm.testimonials),function(testimonial,i){return _c(testimonial.component,_vm._b({key:'testimonial' + i,tag:"component",staticClass:"influencer-testimonials__slider__testimonial",class:_vm.activeClass(i),attrs:{"active":_vm.active(i),"scroll-direction":_vm.scrollDirection,"desktop-padding":_vm.desktopPadding,"index":i,"intersection-observer":_vm.intersectionObserver},on:{"scrollData":_vm.scrollTo}},'component',testimonial,false))}),_vm._v(" "),_c('div',{staticClass:"final-margin"})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }